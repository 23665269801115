import React, { useState, useEffect, useRef } from 'react';
import configImages from '../../../config/configImages';
import fetchMethodRequest from '../../../config/service';
import Config from '../../../config/config';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';


const ShareProperty = (props) => {
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [toggleMenu, setToggleMenu] = useState(false);
    const componentRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                if (props.multipleShare) {
                    setToggleMenu(false);
                } else {
                    setOpenDropdownId(null);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [props.multipleShare]);

    const handleGLobalShare = async () => {
        let propsToShare = props.selectedProperties ? props.selectedProperties() : null;
        if(propsToShare?.length > 0){
            setSelectedProperties(propsToShare)
            setToggleMenu(!toggleMenu);
        } else {
            setToggleMenu(false);
        }

        if (propsToShare.length > Config.shareLimit) {
            // Need to write the warning
        }
    };

    const generateLink = async () => {
        let selectedProperties = props.selectedProperties ? props.selectedProperties() : null;
        let url = 'sharedCriteria';
        let body = { type: 'propertyIds' };

        if (props.item && !props.multipleShare) {
            body.propertyIds = [props.item?._id];
        } else if (selectedProperties.length > 0) {
            body.agentEmail = selectedProperties[0].email;
            body.propertyIds = selectedProperties.map(prop => prop._id);
        }
        if (props.item && props.item.email) {
            body.agentEmail = props.item.email;
        }
        return fetchMethodRequest('POST', url, body);
    };

    const shareLink = async (platform) => {
        let res = await generateLink();
        let link = res.link;
        if (!link) return;

        let url;
        if (platform === 'WhatsApp') {
            const whatsappURL = encodeURIComponent(`Check out this link: ${link}`);
            url = `https://wa.me?text=${whatsappURL}`;
        } else if (platform === 'Telegram') {
            const telegramURL = encodeURIComponent(`Check out this link: ${link}`);
            url = `https://t.me/share/url?url=${telegramURL}`;
        } else {
            console.error('Unsupported platform');
            return;
        }

        if (navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)) {
            window.location.href = url;
        } else {
            const windowFeatures = 'menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=800,height=600';
            const newWindow = window.open(url, '_blank', windowFeatures);

            if (newWindow) {
                newWindow.focus();
            } else {
                alert('Failed to open the new window. Please allow pop-ups for this website.');
            }
        }
        if (props.multipleShare) {
            setToggleMenu(false);
        } else {
            setOpenDropdownId(null);
        }
    };

    return (
        <span className='position-relative'ref={componentRef}>
            {props.multipleShare ?
                // <Button    //commented for the future implementation
                //     size="small"
                //     id={`navbarDropdown-${props.item ? props.item?._id : ''}`}
                //     aria-expanded={props.multipleShare && toggleMenu}
                //     color='primary'
                //     disabled={props.isDisable}
                //     onClick={handleGLobalShare}
                //     style={{height: '100%'}}
                // >
                //     <FontAwesomeIcon
                //         icon={faShareAlt} 
                //         className='shareIconAlignment cursorPointer ml-auto mb-1 mr-0 ml-0'
                //         />
                // </Button> 
                <div className='global-share-wrapper'>
                    <Button color='primary' size="small" disabled={props?.selectedProperties()?.length <=0} onClick={() => shareLink('WhatsApp')} style={{height: '100%'}}>
                    <FontAwesomeIcon
                        icon={faWhatsapp}
                        className='cursorPointer'
                        title='Share Via WhatsApp'
                    />
                    </Button>
                    <Button color='primary' size="small" className='mx-2' style={{height: '100%'}}  onClick={() => shareLink('Telegram')} disabled={props?.selectedProperties()?.length <=0}>
                    <FontAwesomeIcon
                        icon={faTelegram}
                        className='cursorPointer'
                        title='Share Via Telegram'
                        style={{ color: '#fff' }}
                    />
                    </Button>
                </div>
                :
                <span className='d-flex justify-content-between align-items-center w-45'>
                     <FontAwesomeIcon
                        icon={faTelegram}
                        className='cursorPointer mx-2 telegram-color'
                        title='Share Via Telegram'
                        onClick={() => shareLink('Telegram')}
                    />
                    <FontAwesomeIcon
                        icon={faWhatsapp}
                        className='cursorPointer whatsapp-color'
                        title='Share Via WhatsApp'
                        onClick={() => shareLink('WhatsApp')}
                    />

                </span>
            }
            {((props.item?._id && openDropdownId === props.item?._id) || (props.multipleShare && toggleMenu)) && (
                <ul className="dropdown-menu show d-flex justify-content-start share-menu" aria-labelledby={`navbarDropdown-${props.item ? props.item?._id : ''}`}>
                    <li className="dropdown-item" onClick={() => shareLink('WhatsApp')}>
                    <FontAwesomeIcon
                        icon={faWhatsapp}
                        className='shareIcons cursorPointer whatsapp-color'
                        title='Share Via WhatsApp'
                        size='2x'
                    />
                    </li>
                    <li className="dropdown-item" onClick={() => shareLink('Telegram')}>
                    <FontAwesomeIcon
                        icon={faTelegram}
                        className='shareIcons cursorPointer mx-2 telegram-color'
                        title='Share Via Telegram'
                        size='2x'
                    />
                    </li>
                </ul>
            )}
        </span>
    );
};

export default ShareProperty;
