import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import menuItem from '../MenuItem';

const SidebarContent = (props) => {
  const propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  const hideSidebar = () => {
    const { onClick } = props;
    onClick();
  };

  const SidebarMenus = (item, index, type) => {
    return (
      <SidebarLink 
        key={index}
        title={item.displayTitle}
        icon={menuItem.getIcon(item)}
        onClick={hideSidebar}
        iconType={item.iconType}
        route={item.route}
        isSubmenu={type}
      />
    )
  }

  const { menuList } = props;

  return (
    <div className="sidebar__content"
      style={menuList && menuList.length > 14 ? { "height": 40 * menuList.length } : { "height": 'calc(100vh - 60px)' }}
    >
      <ul className="sidebar__block">
        {menuList && menuList.length > 0
          ? menuList.map((item, index) => {
            if (item.submenus && item.submenus.length > 0) {
              return (
                <SidebarCategory
                  key={index}
                  title={item.displayTitle == 'Settings' ? 'Settings Menu' : item.displayTitle}
                  icon={item.iconLetters}
                  iconType={item.iconType}
                >
                  {item.submenus.map((sitem, sindex) => {
                    return (
                      SidebarMenus(sitem, sindex, true)
                    )
                  })}
                </SidebarCategory>
              )
            } else {
              return (
                SidebarMenus(item, index, false)
              );
            }
          })
          : null}
      </ul>
    </div>
  );
}
export default SidebarContent;

