function SearchFilter(values, currentFilterCriteria, filterCriterias) {

    const formFilter = () => {
        let criteria = [];
        let filterCriteria = currentFilterCriteria ? currentFilterCriteria : filterCriterias;
        filterCriteria.criteria = [];

        if (values.propertyFor) {
            criteria.push({ key: 'propertyFor', value: values.propertyFor, type: 'eq' })
        }
        if (values.propertyType) {
            criteria.push({ key: 'propertyType', value: values.propertyType, type: 'eq' })
        }
        if (values.locality) {
            criteria.push({ "key": "$or", "value": ["city", "locality", values.locality], "type": "value" })
        }
        if (values.totalPriceMin || values.totalPriceMax) {
            if (values.totalPriceMin && values.totalPriceMax) {
                criteria.push({ key: 'price', value: { "$gte": values.totalPriceMin.replace(/,/g, ''), "$lte": values.totalPriceMax.replace(/,/g, '') }, type: 'eq' })
            } else if (values.totalPriceMin) {
                criteria.push({ key: 'price', value: { "$gte": values.totalPriceMin.replace(/,/g, '') }, type: 'eq' })
            }
            else if (values.totalPriceMax) {
                criteria.push({ key: 'price', value: { "$lte": values.totalPriceMax.replace(/,/g, '') }, type: 'eq' })
            }
        }
        if (values.builtUpAreaMin || values.builtUpAreaMax) {
            if (values.builtUpAreaMin && values.builtUpAreaMax) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin.replace(/,/g, ''), "$lte": values.builtUpAreaMax.replace(/,/g, '') }, type: 'eq' })
            } else if (values.builtUpAreaMin) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin.replace(/,/g, ''), "$lte": values.builtUpAreaMax }, type: 'eq' })
            } else if (values.builtUpAreaMax) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin, "$lte": values.builtUpAreaMax }, type: 'eq' })
            }
        }
        if (values.facing?.length > 0) {
            criteria.push({ key: 'facing', value: values.facing, type: 'in' })
        }
        if (values.bedRooms?.length > 0) {
            criteria.push({ key: 'bedRooms', value: values.bedRooms, type: 'in' })
        }
        if (values.availability?.length > 0) {
            criteria.push({ key: 'availability', value: values.availability, type: 'in' })
        }
        if (values.furnishingType?.length > 0) {
            criteria.push({ key: 'furnishingType', value: values.furnishingType, type: 'in' })
        }
        if (values.amenties?.length > 0) {
            criteria.push({ key: 'amenties', value: values.amenties, type: 'in' })
        }

        if (criteria && criteria.length > 0) {
            filterCriteria.criteria = criteria;
        } else {
            filterCriteria.criteria = [];
        }
        if (values && values.facing === null) {
            delete values['facing'];
        }
        
        return filterCriteria;
    };

    return formFilter();
}
export default SearchFilter;
