import React from "react";
import { Calendar } from 'primereact/calendar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";


const DoDateField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const name = props.name;
  const placeholder = props.placeholder;
  const selectionMode = props.selectionMode;
  const showButtonBar = props.showButtons;
  let field = props.field;
  let timeOnly = props.timeOnly

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  const onChange = (e) => {
    field.onChange(e)
    if(props.onChange) props.onChange(e);
  }

  return (
    <div className="flex flex-column ">
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <Calendar
        showButtonBar={showButtonBar ? true : false}
        selectionMode={selectionMode ? selectionMode : 'single'}
        inputId={field.name}
        value={field.value}
        onChange={onChange}
        dateFormat="dd/mm/yy"
        placeholder={placeholder}
        timeOnly={timeOnly}
        showIcon
        minDate={props.minDate}
      />
      <small className="text-danger mt-1">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  )

}

export default DoDateField;