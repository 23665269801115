import fetch from "../../config/service";

const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

export default {
    getMenuListFromServer: async () => {
        let loginCredentials = localStorage.getItem("loginCredentials");
        if (loginCredentials) {
            loginCredentials = JSON.parse(loginCredentials);
        }

        if (loginCredentials) {
            let filterCriteria = {}, url;
            filterCriteria.sortfield = 'sequenceNo';
            filterCriteria.direction = 'asc';
            url = `menus?filter=${JSON.stringify(filterCriteria)}`;
            return fetch('GET', url)
                .then(async (res) => {
                    if (res && res.menulists && res.menulists.length > 0) {
                        let menuList = res.menulists;
                        let rolePermissions = localStorage.getItem("rolePermissions");
                        rolePermissions = JSON.parse(rolePermissions);
                        if (rolePermissions) {
                            let newMenuList = [];
                            let keys = Object.keys(rolePermissions);
                            if (menuList) {
                                menuList.forEach((item) => {
                                    if (item.submenus && item.submenus.length > 0) {
                                        let newSubmenus = [];
                                        item.submenus.map(sitem => {
                                            keys.forEach(element => {
                                                if (camelizedString(sitem.displayTitle) === camelizedString(element)) {
                                                    if (rolePermissions[element].type !== "NoView") {
                                                        newSubmenus.push(sitem);
                                                    }
                                                }
                                            });
                                        })
                                        if (newSubmenus && newSubmenus.length > 0) {
                                            item.submenus = newSubmenus;
                                            newMenuList.push(item);
                                        }
                                    } else {
                                        keys.forEach(element => {
                                            if (camelizedString(item.displayTitle) === camelizedString(element)) {
                                                if (rolePermissions[element].type !== "NoView") {
                                                    newMenuList.push(item);
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                            return newMenuList;
                        }
                    }
                })
        }
    },
    getIcon: (menu) => {
        if (menu.iconType) {
            if (menu.iconType === 'icon') {
                return (menu.iconName ? menu.iconName : 'user');
            } else if (menu.iconType === 'letters') {
                return (menu.iconLetters ? menu.iconLetters : menu.displayTitle.substring(0, 2));
            }
        } else {
            return menu.displayTitle.substring(0, 2);
        }
    },
}
