import { React, useEffect, useState } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import '../../../scss/component/time-picker.scss';
import config from '../../../config/config';

const CustomTimePicker = ({ label, field, fieldState, errors, item, markReq, type, selectedDate }) => {

    const [validTime, setValidTime] = useState(field.value ? moment(field.value, config.timeFormat) : null);
    const isToday = selectedDate && moment(selectedDate).isSame(moment(), 'day');     // Check if the selected date is today

    useEffect(() => {
        // Check if the current time is valid
        const isValidTime = validTime && !disabledHours().includes(validTime.hours()) && !disabledMinutes(validTime.hours()).includes(validTime.minutes());
        
        if (!isValidTime && isToday) {
            setValidTime(null);  // Clear the value if it's invalid for today
        }
    }, [validTime, isToday, selectedDate]);

    const handleTimeChange = (value) => {
        // Ensure the format for the time is consistently in 12-hour AM/PM format
        const formattedTime = value ? value.format(config.timeFormat) : '';
        field.onChange(formattedTime);
        setValidTime(value);
    };

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    // Get current hours and minutes
    const currentMoment = moment();
    const currentHours = currentMoment.hours();
    const currentMinutes = currentMoment.minutes();

    const disabledHours = () => {
        if (!isToday) return [];
        const hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < currentHours) {
                hours.push(i);
            }
        }
        return hours;
    };

    const disabledMinutes = (selectedHour) => {
        if (!isToday) return [];
        const minutes = [];
        if (selectedHour === currentHours) {
            for (let i = 0; i < 60; i++) {
                if (i < currentMinutes) {
                    minutes.push(i);
                }
            }
        }
        return minutes;
    };
    
    return (
        <div className="flex flex-column">
            <div className="flex align-center">
                <label className="text-capitalize">{label}</label>
                {markReq && markReq === true && markRequired()}
            </div>
            <TimePicker
                showSecond={false}
                value={field.value ? moment(field.value, config.timeFormat) : null}
                onChange={handleTimeChange}
                format={'h:mm A'} // Explicitly sets the format
                use12Hours={true}
                inputReadOnly
                minuteStep={5}
                placeholder={'Time'}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
            />
            <small className="text-danger">{fieldState.invalid ? errors[field.name]?.message : ''}</small>
        </div>
    );
};

export default CustomTimePicker;
