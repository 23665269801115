import React, { useEffect, useState } from 'react';
import {Scrollbar} from 'smooth-scrollbar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps'; 
import menuItem from '../MenuItem';


const Sidebar = (props) => {
  
  const [menuList, setMenuList] = useState([]);  

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    const menuItems = await menuItem.getMenuListFromServer();
    setMenuList(menuItems);
  };

  const SidebarData = (mobileVisibility) => {
    return (
      <SidebarContent
        onClick={mobileVisibility}
        changeToDark={changeToDark}
        changeToLight={changeToLight}
        menuList={menuList}
      />
    )
  }

  const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = props;
  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': sidebar.show,
    'sidebar--collapse': sidebar.collapse,
    'd-block d-md-none': true,
  });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            {SidebarData()}
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            {SidebarData(changeMobileSidebarVisibility)}
          </div>
        </Scrollbar>
      </div>
    );
  };


Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;