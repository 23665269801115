import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

import config from '../../../config/config';
import configImages from '../../../config/configImages';
//session expiry modal
import SessionExpiryModal from '../../Cruds/CommonModals/SessionexpiryModal'
import menuItem from '../MenuItem';

const Topbar = (props) => {
  const [sessionExpiryModal, setsessionExpiryModal] = useState(false);
  const [menuList, setMenuList] = useState([]);

  let navBarItems = ['/myProperties', '/clients', '/searches', '/dashboard'];

  useEffect(() => {
    let sessionexpired = localStorage.getItem('sessionexpired')
    if (sessionexpired == "true") {      
      setsessionExpiryModal(true);
    }
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    const menuItems = await menuItem.getMenuListFromServer();
    const updatedMenuItems = menuItems.map(item => {
      if (item.displayTitle.includes("Searches")) {
        return {
          ...item,
          displayTitle: 'Search'
        };
      }
      return item;
    });    
    setMenuList(updatedMenuItems);
  };

  const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left position-absolute top-0 start-0">
          <span className='d-block d-md-none'>
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
          </span>
          <span className='d-none d-md-block mt-2'>
            <Link className='topbarLogo ms-3' to="/dashboard">
              {config.displayProjectName ?
                <img src={configImages.loginImage} className='topbarProjectLogo' alt="Project Logo" />
                : config.appName}
            </Link>
          </span>         
        </div>
        <div className="topbar__right">
          <div className="d-none d-md-block">
            <ul className="topbar__menu-list d-flex align-items-center h-100">
              {menuList && menuList.length > 0 && menuList.map((item, index) => {
                if (item.submenus && item.submenus.length > 0) {
                  return (
                    <li key={index} className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id={`navbarDropdown-${index}`}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {item.displayTitle}
                      </a>
                      <ul className="dropdown-menu" aria-labelledby={`navbarDropdown-${index}`}>
                        {item.submenus.map((sitem, sindex) => (
                          <li key={sindex}>
                            <NavLink
                              to={sitem.route}
                              className="dropdown-item"
                            >
                              {sitem.displayTitle}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                } else if (navBarItems.some(navItem => item.route.includes(navItem))) {
                  return (
                    <li key={index} className="topbar__menu-item">
                      <NavLink
                        to={item.route}
                        className={({ isActive }) => isActive ? "topbar_navlink activeTopBar" : "topbar_navlink"}
                      >
                        {item.displayTitle}
                      </NavLink>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <TopbarProfile />
        </div>

        {sessionExpiryModal && (
          <SessionExpiryModal SOpen={sessionExpiryModal} />
        )}
      </div>
    </div>
  );
}

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
