import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tickets from './components/Tickets';

const tickets = (props,{ t }) => (
  <Container>
    <Tickets {...props} />
  </Container>
);

tickets.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(tickets);
