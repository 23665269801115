export const PropertyAeraOptions  = [
    {
      label: "Sq.Ft.",
      value: "sqft",
      color: "success",
    },
    {
      label: "Sq.Yards",
      value: "sqyards",
      color: "danger",
    },
    {
      label: "Sq.M.",
      value: "sqm",
      color: "warning",
    }, 
    {
        label: "Aankadam",
        value: "aankadam",
        color: "secondary",
    },
    {
        label: "Guntha",
        value: "guntha",
        color: "success",
    },
    {
        label: "Cents",
        value: "cents",
        color: "danger",
    },
    {
        label: "Acres",
        value: "acres",
        color: "info",
    },
    {
        label: "Hectares",
        value: "hectares",
        color: "dark",
    },
    {
      label: "Grounds",
      value: "grounds",
      color: "info",
    },
    {
        label: "Kanal",
        value: "kanal",
        color: "success",
    },
    {
      label: "Rood",
      value: "rood",
      color: "primary",
    },
    {
      label: "Chataks",
      value: "chataks",
      color: "dark",
    },
    {
      label: "Perch",
      value: "perch",
      color: "light",
    },
    {
      label: "Ares",
      value: "ares",
      color: "danger",
    },
    {
      label: "Biswa",
      value: "biswa",
      color: "warning",
    },
    {
      label: "Bigha",
      value: "bigha",
      color: "secondary",
    },
    {
      label: "Kottah",
      value: "kottah",
      color: "primary",
    },
    {
      label: "Marla",
      value: "marla",
      color: "light",
    },
  ];
  