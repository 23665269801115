import React, { useState } from 'react';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CountDisplay = (props) => {

	const [itemsList] = useState([
		{ displayName: 'My Properties', route: '/myProperties', color: '#39cccc', key: 'myProperties', icon: 'warehouse', count: 'noOfPropertiesCount' },
		{ displayName: 'My Clients', route: '/clients', color: '#d2acac', key: 'clients', icon: 'users', count: 'noOfclientsCount' },
		{ displayName: 'My Saved Searches', route: '/savedSearches', color: '#72c872', key: 'savedSearches', icon: 'heart', count: 'noOfclientCriteriaCount' },
		{ displayName: 'Matched Properties', route: '/showMatchedProperties', color: 'rgb(217 141 73)', key: 'matchedProperties', icon: 'house-user', count: 'noOfMatchedPropertiesCount' }
	]);


	return (
		<Container className='px-0'>
			<Row>
				{itemsList.map((item, index) => (
					<Col md={12} xl={3} lg={6} xs={12} key={index}>
						<Link to={item.route}>
							<Card className='dashboard_cards'>
								<CardBody className="dashboard__card-widget d-flex justify-content-between" style={{ backgroundColor: item.color }}>
									<div>
										<div className="card__title">
											<h5 className="bold-text text-white mx-3" >{item.displayName}</h5>
										</div>
										<div className="dashboard__total text-white">
											<span>
												{props?.dashboardData?.[item.count] || '0'}
											</span>
										</div>
									</div>
									<div className="card-icon tile-icons">
										<FontAwesomeIcon icon={["fa", item.icon]} className="menu-icon" />
									</div>
								</CardBody>
							</Card>
						</Link>
					</Col>
				))}
			</Row>
		</Container >
	);
}

export default CountDisplay;