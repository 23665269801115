import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ShowMatchedProperties from './components/ShowMatchedProperties';

const showMatchedProperties = (props, { t }) => (
  <Container>
    <ShowMatchedProperties {...props} />
  </Container>
);

showMatchedProperties.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(showMatchedProperties);
