import React from "react";
import { Password } from 'primereact/password';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoPasswordField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;   
    let field = props.field;
    const placeholder = props.placeholder ? props.placeholder : props.name;

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };
     

    return (
        <div className="flex flex-column">
            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <Password id={id} name={name}  {...field} inputRef={field.ref} placeholder={placeholder}  feedback={false} toggleMask />  
            <small className="text-danger mt-1">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>         
        </div>
    )

}

export default DoPasswordField;