import React from "react";
import { Checkbox } from 'primereact/checkbox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoCheckboxField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;    
    const name = props.name;   
    let field = props.field;
    const options = props.options || [];
    const multiple = props.multiple;
    const multipleSelect = props.multipleSelect;

    const onChange = (e) => {
        if(props.onChangeFn){
            props.onChangeFn(e);
        }
    }

    const onCheckboxChange = (e, option) => {
        const checked = e.checked;
        
        if (multipleSelect) {
            const newValue = checked
                ? [...(field.value || []), option.value]
                : (field.value || []).filter(val => val !== option.value);
            field.onChange(newValue);
        } else {
            const newValue = checked ? [option.value] : null;
            field.onChange(newValue);           
        }
    };

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
            />
        );
    };

    return (
        !multiple ? (
            <div className="flex flex-column pt-1">
                <div className="flex align-items-center">
                    <Checkbox
                        inputId={field.name}
                        checked={field.value}
                        inputRef={field.ref}
                        name={field.name}
                        onChange={(e) => { field.onChange(e.checked); onChange(e); }}
                    />
                    <div className="flex align-center">
                        <label htmlFor={field.name} className="text-capitalize" style={{ paddingLeft: '6px' }}>
                            {label}
                        </label>
                        {props.markReq && props.markReq === true && markRequired()}
                    </div>
                </div>
                <small className="text-danger" style={{ marginTop: "5px" }}>
                    {props.fieldState?.invalid ? props.errors[props.name]?.message : ''}
                </small>
            </div>
        ) : (
            <div className="flex flex-column pt-1">
                <div className="flex align-center mb-1">
                    <label htmlFor={id} className="text-capitalize">{label}</label>
                    {props.markReq && props.markReq === true && markRequired()}
                </div>
                <div className="row">
                    {options.map(option => (
                        <div key={option.value} className={`flex align-items-center mb-2 ${props.customWidth}`}>
                            <Checkbox
                                inputId={option.value}
                                checked={multipleSelect ? 
                                    Array.isArray(field.value) && field.value.includes(option.value)
                                    : field.value[0] === option.value} // Restrict to one selection
                                onChange={(e) => onCheckboxChange(e, option)}
                            />
                            <label htmlFor={option.value} className="text-capitalize" style={{ paddingLeft: '6px' }}>
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
                <small className="text-danger" style={{ marginTop: "5px" }}>
                    {props.fieldState.invalid ? props.errors[props.name]?.message : ''}
                </small>
            </div>
        )
    )

}

export default DoCheckboxField;